import docReady from "./docReady";
import instantsearch from 'instantsearch.js/es/index';
import {
  searchBox,
  configure,
  hits
} from 'instantsearch.js/es/widgets';
import {
  instantMeiliSearch
} from '@meilisearch/instant-meilisearch';

const searchFactory = (
  searchBoxContainer: string,
  hitsContainer: string,
  prefixClassName: string,
) => {
  const search = instantsearch({
    indexName: "ciks3",
    searchClient: instantMeiliSearch(
      "https://search.stockocean.com",
      "rje5krn3vgn0nutxRQX",
      {
        placeholderSearch: false,
      }
    )
  });
  search.addWidgets([
    searchBox({
      container: searchBoxContainer,
      cssClasses: {
        input: `search-${prefixClassName}`,
        resetIcon: `search-resetIcon-${prefixClassName}`,
        submit: `search-submit-${prefixClassName}`,
      },
      placeholder: 'Search ticker, company, or person',
    }),
    configure({ hitsPerPage: 8 }),
    // @ts-ignore
    hits({
      container: hitsContainer,
      cssClasses: {
        emptyRoot: `search-empty-${prefixClassName}`,
        item: `search-item-${prefixClassName}`,
      },
      templates: {
        // @ts-ignore
        empty(results, { html }) {
          html``;
        },
        item: (hit, { html, components }) =>
          hit.symbol ? html`
            <a style="display: flex; align-items: center; text-decoration: none;" href="${hit.url}">
              <img src="https://cdn.stockocean.com/logo/${hit.logoid}.svg" style="width: 16px; height: 16px; margin-right: 8px;" />
              <span>
                ${components.Highlight({ attribute: 'name', hit })}
              </span>
              <span style="margin-left: auto;">
                ${components.Highlight({ attribute: 'symbol', hit })}
              </span>
            </a>
          ` : html`
            <a href="${hit.url}">${components.Highlight({ attribute: 'name', hit })}</a>
          `
      },
      transformItems(items) {
        return items.map((item) => {
          const { cik, name, symbol, } = item;
          let url = '';
          if (symbol) {
            // @ts-ignore
            url = `/company/${symbol.toLowerCase()}`;
          } else {
            // @ts-ignore
            const normalizedName = name
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g,'')
              .replace(/\s+/g,'-')
              .toLowerCase()
              .replace(/&/g,'-and-')
              .replace(/[^a-z0-9\-]/g,'')
              .replace(/-+/g,'-')
              .replace(/^-*/,'')
              .replace(/-*$/,'');
            url = `/entity/${cik}-${normalizedName.toLowerCase()}`;
          }
          return {
            ...item,
            url,
          };
        });
      }
    })
  ]);

  search.start();

  document.querySelector(`.search-submit-${prefixClassName}`)?.addEventListener('click', (e) => {
    if (curr != null) {
      // @ts-ignore
      window.location.href = document.querySelector(`.search-${prefixClassName}.search-highlighted-${prefixClassName} a`)?.href;
    } else {
      // @ts-ignore
      window.location.href = `/search?q=${document.querySelector(`.search-${prefixClassName}`)?.value}`;
    }
  });

  let curr = null;
  document.querySelector(`.search-${prefixClassName}`)?.addEventListener('keydown', (e) => {
    // @ts-ignore
    if (e.code == 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (curr != null) {
        // @ts-ignore
        window.location.href = document.querySelector(`.search-item-${prefixClassName}.search-highlighted-${prefixClassName} a`)?.href;
      } else {
        // @ts-ignore
        window.location.href = `/search?q=${document.querySelector(`.search-${prefixClassName}`)?.value}`;
      }
      // @ts-ignore
    } else if(e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      if (document.querySelectorAll(`.search-empty-${prefixClassName}`).length == 0) {
        const resultCount = document.querySelectorAll(`.search-item-${prefixClassName}`).length;
        // @ts-ignore
        if (e.key === 'ArrowDown') {
          // @ts-ignore
          curr = curr != null ? (curr + 1) % resultCount : 0;
        } else {
          // @ts-ignore
          curr = curr != null ? Math.max((curr - 1) % resultCount, 0) : resultCount - 1;
        }

        document
          .querySelectorAll(`.search-item-${prefixClassName}`)
          .forEach((element) => {
            element.classList.remove(`search-highlighted-${prefixClassName}`);
        });

        // @ts-ignore
        document.querySelector(`.search-item-${prefixClassName}:nth-of-type(${curr + 1})`)?.classList.add(`search-highlighted-${prefixClassName}`);
      }
    }
  });

  document.querySelector(`.search-${prefixClassName}`)?.addEventListener('blur', () => {
    curr = null;
  });
};

docReady(() => {
  // @ts-ignore
  if (window.headerDisabled !== undefined) { return; }

  searchFactory(
    '#searchbox-main',
    '#hits-main',
    'main',
  );

  searchFactory(
    '#searchbox-mobile',
    '#hits-mobile',
    'mobile',
  );

  if (window.screen.width > 576 && window.location.pathname == '/') {
    // @ts-ignore
    document.querySelector('.search-main')?.focus();
  }
});